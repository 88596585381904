import { Box, Center, List, Spinner, Stack } from "@wiretronic/iris";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { ListBar } from "../components/ListBar";
import { ProductListItem } from "../components/ProductListItem";
import SideBar from "../components/SideBar";
import { ProductDatabaseConnection } from "../db-client/ProductDatabaseConnection";
import { getUserHistory } from "../db-client/UserGeneratedDataConnection";
import { NodeWithEdges } from "../interfaces";

export default function SearchHistoryPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState<NodeWithEdges[]>([]);
  const [queryString, setQueryString] = useState("");
  const [page, setPage] = useState(1);
  const maxFetchLimit = 50;

  useEffect(() => {
    const abortController = new AbortController();
    const userHistory = () => {
      getUserHistory(abortController)
        .then(async (nodeIds) => {
          setProducts(await productHistorylist(nodeIds));
          setIsLoading(false);
        })
        .catch((reason) => {
          if (!abortController.signal.aborted) {
            console.warn(reason);
          }
        });
    };

    userHistory();

    return () => {
      abortController.abort();
    };
  }, [queryString]);

  const productHistorylist = async (viewingHistoryNodeIds: {
    "viewing-history": number[];
  }): Promise<NodeWithEdges[]> => {
    const products = viewingHistoryNodeIds["viewing-history"].reverse().map(async (productId) => {
      const prod = await ProductDatabaseConnection.getProduct(productId);

      return prod;
    });
    const productArray = await Promise.all(products);

    return productArray.filter((product) => product) as NodeWithEdges[];
  };

  const pagination = (prods: (NodeWithEdges | undefined)[]) => {
    const indexOfLastProd = page * maxFetchLimit;
    const indexOfFirstProd = indexOfLastProd - maxFetchLimit;
    return prods.slice(indexOfFirstProd, indexOfLastProd);
  };

  const shiftPage = (by: number) => {
    if (page + by < 1 || (pagination(products).length < maxFetchLimit - 1 && by > 0)) {
      return;
    }

    const nextPage = Math.max(1, page + by);
    setPage(nextPage);
  };

  const ListElement = () => {
    if (isLoading) {
      return (
        <Center>
          <Spinner />
        </Center>
      );
    }

    if (products.length >= 1) {
      return (
        <List width={"100%"}>
          {pagination(products).map((element) => {
            return <ProductListItem key={element!.node.id} element={element!.node} url={"/history/"} />;
          })}
        </List>
      );
    }

    return <></>;
  };

  return (
    <Stack height={"100%"} direction="row">
      <Box flex={1} justifyContent={"center"} overflowY={"auto"}>
        <Outlet />
      </Box>
      <SideBar
        TopElement={
          <ListBar
            setQueryString={setQueryString}
            setPage={setPage}
            page={page}
            isLoading={isLoading}
            shiftPage={shiftPage}
          />
        }
        ListElement={<ListElement />}
      />
    </Stack>
  );
}
