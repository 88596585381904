import { IrisProvider } from "@wiretronic/iris";
import { BrowserRouter } from "react-router-dom";
import { MainRoutes } from "./Routes";

function App() {
  return (
    <IrisProvider>
      <BrowserRouter>
        <MainRoutes />
      </BrowserRouter>
    </IrisProvider>
  );
}

export default App;
