import { Box, Center, Heading, HStack, ListItem } from "@wiretronic/iris";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { getStringValue } from "../lib/node";
import Setting from "../lib/setting";
import { Node } from "../interfaces";

type ProductListItemProps = {
  element: Node;
  url: string;
};

export const ProductListItem = (props: ProductListItemProps) => {
  const articleNumberSetting = new Setting("article_number_key");
  const ProductThumbnail = () => {
    const thumbnail = getStringValue("thumbnail", props.element.attributes);
    if (thumbnail == undefined) {
      return <Box w={"60px"} h={"60px"} />;
    } else {
      return (
        <Center w={"60px"} h={"60px"}>
          <img width={"30px"} height={"30px"} src={thumbnail}></img>
        </Center>
      );
    }
  };

  return (
    <ListItem key={props.element.id} borderBottom={"1px"} borderColor={"var(--chakra-colors-chakra-border-color)"}>
      <Link to={`${props.url}${props.element.id}`}>
        <HStack h={"60px"}>
          <ProductThumbnail />
          <Heading size={"md"}>{props.element.attributes[articleNumberSetting.getStoredValue()] as ReactNode}</Heading>
        </HStack>
      </Link>
    </ListItem>
  );
};
