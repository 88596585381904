import { Login } from "@wiretronic/iris";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../interfaces";
import { login } from "../lib/login";

interface loginProps {
  setUser: (value: User | null) => void;
}

export default function LoginPage({ setUser }: loginProps) {
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const err = "Incorrect email & password combination";
  const navigate = useNavigate();

  const handleSubmit = async (email: string, password: string) => {
    setIsLoading(true);
    login(email, password)
      .then((user) => {
        if (user) {
          setUser(user);
          navigate("/product");
        } else {
          setError(err);
        }
      })
      .catch(() => setError(err))
      .finally(() => setIsLoading(false));
  };

  return <Login onSubmit={handleSubmit} error={error} isLoading={isLoading} />;
}
