import { Box, HStack } from "@wiretronic/iris";

export const NoProduct = () => {
  return (
    <Box width={"100%"}>
      <HStack maxHeight={"50vh"} minHeight={"350px"} justifyContent="center">
        <p>No product selected </p>{" "}
      </HStack>
    </Box>
  );
};
