import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { HStack } from "@wiretronic/iris";
import { ReactNode } from "react";
import "./Carousel.css";

type CarouselProps = {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  length: number;
  children: ReactNode;
};

export const Carousel = (props: CarouselProps) => {
  const nextPage = (length: number) => {
    props.setCurrent(props.current === length - 1 ? 0 : props.current + 1);
  };

  const prevPage = (length: number) => {
    props.setCurrent(props.current === 0 ? length - 1 : props.current - 1);
  };

  return (
    <HStack w={"100%"} h={"100%"} justifyContent={"space-evenly"} alignContent={"center"}>
      <ChevronLeftIcon className="left-arrow" onClick={() => prevPage(props.length)} position={"static"} />
      {props.children}
      <ChevronRightIcon className="right-arrow" onClick={() => nextPage(props.length)} position={"static"} />
    </HStack>
  );
};
