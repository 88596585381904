import { RequestParams } from "../interfaces";

const ugdBaseUrl = process.env.REACT_APP_UGD_BASE_URL ?? "";

const _getHeaders = (): Headers => {
  const token = JSON.parse(localStorage.getItem("user") ?? '{"token": ""}')["token"];
  return new Headers({
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  });
};

const makeRequest = async ({ url, method }: RequestParams, abortController?: AbortController) => {
  const requestBody: RequestInit = {
    headers: _getHeaders(),
    method,
    signal: abortController?.signal,
  };

  const res = await fetch(ugdBaseUrl + url, requestBody);

  if (res.ok) {
    return await res.json();
  } else if (res.status === 404) {
    return;
  } else {
    throw Error(`Response code ${res.status} - ${res.statusText}`);
  }
};

export const getUserHistory = async (abortController?: AbortController) => {
  const url = "user-generated-data/?key=viewing-history";

  const res = await makeRequest({ url, method: "get" }, abortController);

  return res ?? ({ "viewing-history": [] } as { "viewing-history": number[] });
};

export const getUserFavourites = async (abortController?: AbortController) => {
  const url = "user-generated-data/?key=favourites-list";

  const res = await makeRequest({ url, method: "get" }, abortController);

  return res ?? ({ "favourites-list": [] } as { "favourites-list": number[] });
};

export const setUserFavourite = async (nodeId: number, value: boolean, abortController?: AbortController) => {
  const url = "user-generated-data/?key=favourites-list";
  const favList = (await getUserFavourites())["favourites-list"];

  if (favList.includes(nodeId)) {
    if (!value) {
      favList.splice(favList.indexOf(nodeId), 1);
    }
  } else if (value) {
    favList.push(nodeId);
  }

  const requestBody: RequestInit = {
    headers: _getHeaders(),
    method: "put",
    signal: abortController?.signal,
    body: JSON.stringify({ key: "favourites-list", value: favList }),
  };

  const res = await fetch(ugdBaseUrl + url, requestBody);

  if (!res.ok) {
    throw Error(`Response code ${res.status} - ${res.statusText}`);
  }

  return await res.json();
};
