import Setting from "./lib/setting";

export interface StateContext {
  key: string;
  onKeyUpdateListeners: Array<() => void>;
}

export const defaultState: StateContext = { key: "", onKeyUpdateListeners: [] };

export const setGlobalKeyListeners = (functions: Array<() => void>): void => {
  defaultState.onKeyUpdateListeners = functions;
};

export const setGlobalKey = (key: string): void => {
  defaultState.onKeyUpdateListeners.forEach((func) => {
    func();
  });
  const setting = new Setting("api_key");
  setting.setValue(key);
};

export const getGlobalKey = (): StateContext => {
  const setting = new Setting("api_key");
  return {
    key: setting.getStoredValue() ?? "",
    onKeyUpdateListeners: defaultState.onKeyUpdateListeners,
  };
};
