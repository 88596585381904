import { Box, Center, List, Spinner, Stack } from "@wiretronic/iris";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { ListBar } from "../components/ListBar";
import { ProductListItem } from "../components/ProductListItem";
import SideBar from "../components/SideBar";
import { ProductDatabaseConnection } from "../db-client/ProductDatabaseConnection";
import { Node } from "../interfaces";
import { getGlobalKey } from "../StateManager";

export default function ProductPage() {
  const [items, setItems] = useState<Node[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [queryString, setQueryString] = useState("");
  const [artNumberOnly] = useState(true);
  const maxFetchLimit = 50;

  useEffect(() => {
    const abortController = new AbortController();
    ProductDatabaseConnection.getSimpleProductList(maxFetchLimit, queryString, page, artNumberOnly, abortController)
      .then((simpleProduct) => {
        setItems(simpleProduct ?? []);
        setIsLoading(false);
      })
      .catch((reason) => {
        if (!abortController.signal.aborted) {
          console.warn(reason);
        }
      });

    return () => {
      abortController.abort();
    };
  }, [queryString, page]);

  const shiftPage = (by: number) => {
    if (page + by < 1 || (items.length < maxFetchLimit - 1 && by > 0)) {
      return;
    }
    const nextPage = Math.max(1, page + by);
    setPage(nextPage);
  };

  const ListElement = () => {
    if (isLoading) {
      return (
        <Center>
          <Spinner />
        </Center>
      );
    } else if (!items.length) {
      const message =
        getGlobalKey().key === "" ? (
          <p>Please enter a valid wirevision api-key and then reload the product list.</p>
        ) : (
          <p>No products found.</p>
        );
      return <div className="ui warning message appMessage">{message}</div>;
    } else {
      return (
        <List width={"100%"}>
          {items.map((element) => {
            return <ProductListItem key={element.id} element={element} url={"/product/"} />;
          })}
        </List>
      );
    }
  };

  return (
    <Stack height={"100%"} direction="row" marginTop={0}>
      <Box flex={1} justifyContent={"center"} overflowY={"auto"}>
        <Outlet />
      </Box>
      <SideBar
        TopElement={
          <ListBar
            setQueryString={setQueryString}
            setPage={setPage}
            page={page}
            isLoading={isLoading}
            shiftPage={shiftPage}
          />
        }
        ListElement={<ListElement />}
      />
    </Stack>
  );
}
