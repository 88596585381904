import { Preference, User } from "../interfaces";
import { setGlobalKey } from "../StateManager";

export async function login(email: string, password: string): Promise<User | null> {
  const authEndpoint = process.env.REACT_APP_AUTH_ENDPOINT ?? "";
  const prefEndpoint = process.env.REACT_APP_PREF_ENDPOINT ?? "";

  const response = await fetch(authEndpoint, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
    }),
  });

  if (response.ok) {
    const user = (await response.json()) as User;

    setGlobalKey("");

    const prefResponse = await fetch(prefEndpoint, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    const prefs = (await prefResponse.json()) as Preference[];

    if (prefs.length) {
      setGlobalKey(prefs[0].value);
    }

    return user;
  } else {
    return null;
  }
}
