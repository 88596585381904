import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Button, ButtonGroup, Center, HStack } from "@wiretronic/iris";

type PaginationProps = {
  page: number;
  shiftPage: (by: number) => void;
  isLoading: boolean;
};

export const Pagination = (props: PaginationProps) => {
  const renderPagination = () => {
    if (props.isLoading) {
      return;
    }
    return (
      <ButtonGroup>
        <Button variant={"ghost"} w={"20px"} h={"25px"}>
          <ChevronLeftIcon
            w={"20px"}
            h={"20px"}
            onClick={() => {
              props.shiftPage(-1);
            }}
          />
        </Button>
        <Center w={"50px"}>Page {props.page}</Center>
        <Button variant={"ghost"} w={"20px"} h={"25px"}>
          <ChevronRightIcon
            w={"20px"}
            h={"20px"}
            onClick={() => {
              props.shiftPage(1);
            }}
          />
        </Button>
      </ButtonGroup>
    );
  };
  return (
    <HStack justifyContent={"center"} p={"2"}>
      {renderPagination()}
    </HStack>
  );
};
