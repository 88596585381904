import { Attributes, JSONValue } from "../interfaces";

/** Type predicate function to determine if the value for the specified `key` in `attributes` is of type string */
export function hasStringValue<T extends string>(
  key: T,
  attributes: Attributes
): attributes is { [key in T]: string } & Attributes {
  return key in attributes && typeof attributes[key] === "string";
}

/** Returns the value for specified `key` in `attributes` if it is of type string, else undefined */
export function getStringValue(key: string, attributes: Attributes): string | undefined {
  return hasStringValue(key, attributes) ? attributes[key] : undefined;
}

/** Returns new attributes filtered by the predicate function */
export function filterAttributes(attributes: Attributes, predicate: (key: string) => boolean): Attributes {
  const newKeys = Object.keys(attributes).filter(predicate);
  return Object.fromEntries(newKeys.map((k) => [k, attributes[k]]));
}

/** Returns a string representation of the attribute value */
export function attributeValueToString(x: JSONValue): string {
  if (x === null) return "";
  if (typeof x === "boolean" || typeof x === "number" || typeof x === "string") return x + "";
  if (Array.isArray(x)) return x.map(attributeValueToString).join();
  return Object.keys(x)
    .map((y) => y + ": " + attributeValueToString(x[y]))
    .join();
}
