import { Box, Page } from "@wiretronic/iris";
import { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { User } from "../interfaces";
import { SiteHeader } from "./Header/SiteHeader";

type PageLayoutProps = {
  children?: ReactNode;
  setUser: (value: User | null) => void;
};

export const PageLayout = (props: PageLayoutProps) => {
  const PageContent = () => {
    return (
      <Box height={"100%"} background={"white"}>
        <Outlet />
      </Box>
    );
  };

  return (
    <Box bg="gray.100">
      <Box maxW={2000} mx="auto">
        <Page Header={<SiteHeader setUser={props.setUser} />} Sidebar={<Box />} Content={<PageContent />} />
      </Box>
    </Box>
  );
};
