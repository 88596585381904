import { HStack } from "@wiretronic/iris";
import { Dispatch } from "react";
import { Pagination } from "./Pagination";
import { SearchBar } from "./SearchBar";

type ListBarProps = {
  setQueryString: Dispatch<React.SetStateAction<string>>;
  setPage: Dispatch<React.SetStateAction<number>>;
  page: number;
  isLoading: boolean;
  shiftPage: (by: number) => void;
};

export const ListBar = (props: ListBarProps) => {
  return (
    <HStack justifyContent={"space-between"} width="100%" p={2} position={"absolute"}>
      <SearchBar setQueryString={props.setQueryString} setPage={props.setPage} />
      <Pagination page={props.page} shiftPage={props.shiftPage} isLoading={props.isLoading} />
    </HStack>
  );
};
