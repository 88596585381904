import { Route, Routes } from "react-router-dom";
import { PageLayout } from "./components/PageLayout";

import { tokenIsValid, useUser } from "./lib/useUser";
import LoginPage from "./pages/LoginPage";
import ProductPage from "./pages/ProductPage";
import SearchHistoryPage from "./pages/SearchHistoryPage";
import { SingleProduct } from "./pages/outlet-pages/SingleProduct";
import { NoProduct } from "./pages/outlet-pages/NoProduct";
import FavouritesPage from "./pages/FavouritesPage";

export const MainRoutes = () => {
  const { user, setUser } = useUser();

  const PageRoutes = () => {
    if (!user || !tokenIsValid(user.token)) {
      return (
        <Routes>
          <Route path="/" element={<LoginPage setUser={(value) => setUser(value)} />} />
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route element={<PageLayout setUser={(value) => setUser(value)} />}>
            <Route element={<ProductPage />}>
              <Route path="" element={<NoProduct />} />
            </Route>
            <Route path="product" element={<ProductPage />}>
              <Route path="" element={<NoProduct />} />
              <Route path=":id" element={<SingleProduct />} />
            </Route>
            <Route path="history" element={<SearchHistoryPage />}>
              <Route path="" element={<NoProduct />} />
              <Route path=":id" element={<SingleProduct />} />
            </Route>
            <Route path="favourites" element={<FavouritesPage />}>
              <Route path="" element={<NoProduct />} />
              <Route path=":id" element={<SingleProduct />} />
            </Route>
          </Route>
        </Routes>
      );
    }
  };

  return <PageRoutes />;
};
