import {
  Box,
  Center,
  Heading,
  HStack,
  Spinner,
  Switch,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@wiretronic/iris";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Carousel } from "../../components/Carousel/Carousel";
import { ProductDatabaseConnection } from "../../db-client/ProductDatabaseConnection";
import { getUserFavourites, setUserFavourite } from "../../db-client/UserGeneratedDataConnection";
import { Attributes, JSONValue, NodeWithEdges } from "../../interfaces";
import { attributeValueToString, filterAttributes } from "../../lib/node";
import Setting from "../../lib/setting";

export const SingleProduct = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [current, setCurrent] = useState(0);
  const productId = useParams();
  const [product, setProduct] = useState<NodeWithEdges>({
    node: {
      id: productId["id"],
      attributes: {},
    },
    incoming_edges: [],
    outgoing_edges: [],
  });
  const articleNumberSetting = new Setting("article_number_key");
  const [isFavourite, setIsFavourite] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    ProductDatabaseConnection.getProduct(productId["id"])
      .then((item) => {
        if (item) {
          setProduct(item);
          getUserFavourites().then((favourites: { "favourites-list": number[] }) => {
            setIsFavourite(favourites["favourites-list"].includes(item.node.id));
          });
        }
        setIsLoading(false);
      })
      .catch(console.warn);
  }, [productId["id"]]);

  const attributeArray = (imageAttribute: JSONValue) => {
    if (typeof imageAttribute === "string") imageAttribute = [imageAttribute];
    if (!Array.isArray(imageAttribute)) return [];
    return imageAttribute;
  };

  const renderImage = (imageAttribute: JSONValue) => {
    const attribute = attributeArray(imageAttribute);
    if (isLoading) {
      return (
        <Center>
          <Spinner p="4" />
        </Center>
      );
    }
    const images = attribute.map((image, index) => {
      if (index === current) {
        return (
          <Box key={index} w={"150px"}>
            {<img src={image?.toString()} alt="" />}
          </Box>
        );
      }
    });
    return images;
  };

  const renderSlide = (imageAttribute: JSONValue) => {
    const length = attributeArray(imageAttribute).length;
    if (isLoading) {
      return <Spinner />;
    }

    if (length == 0) {
      return <Box>No image</Box>;
    }

    return (
      <Carousel current={current} setCurrent={setCurrent} length={length}>
        {renderImage(imageAttribute)}
      </Carousel>
    );
  };

  const attributeRow = (value: JSONValue, key: string, index: number) => {
    const stringValues = (Array.isArray(value) ? value : [value]).map(attributeValueToString);

    return (
      <Tr key={index}>
        <Td>{key}</Td>
        <Td>{stringValues}</Td>
      </Tr>
    );
  };

  const renderProperties = (properties: Attributes): JSX.Element => {
    const includedItems = ["sex", "sealed", "colour", "colour_gradient", "cavities"];
    const filteredAttributes = filterAttributes(properties, (attributeKey) => includedItems.includes(attributeKey));

    return (
      <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" maxWidth={700} margin="auto">
        <TableContainer>
          <Table variant="simple">
            <TableCaption>Product properties</TableCaption>
            <Thead>
              <Tr>
                <Th>Property</Th>
                <Th>Value</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.entries(filteredAttributes).map(([key, value], index) => attributeRow(value, key, index))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  return (
    <Box width={"100%"}>
      <Heading width={"100%"} textAlign={"center"} p={4}>
        {product.node.attributes[articleNumberSetting.getStoredValue()]?.toString()}
      </Heading>
      <HStack key={productId["id"]} maxHeight={"50vh"} minHeight={"350px"} justifyContent="center">
        {renderSlide(product.node.attributes["images"])}
      </HStack>
      {renderProperties(product.node.attributes)}
      <Box p={4} textAlign={"center"} width={"100%"}>
        Set as favourite
        <Switch
          isChecked={isFavourite}
          p={2}
          colorScheme="teal"
          size="lg"
          onChange={(e) => {
            const value = e.target.checked;
            setUserFavourite(product.node.id, value);
            setIsFavourite(value);
          }}
        />
      </Box>
    </Box>
  );
};
