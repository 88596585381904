import { Box, Button, Header, Image, Spacer } from "@wiretronic/iris";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import wirevision_logo from "../../images/wirevision_logo.svg";
import { User } from "../../interfaces";
interface SiteHeaderProps {
  setUser?: (value: User | null) => void;
}

const headerLinks = [
  {
    url: "product",
    name: "Products",
  },
  {
    url: "history",
    name: "History",
  },
  { url: "favourites", name: "Favourites" },
];

export const SiteHeader = ({ setUser }: SiteHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const MenuItems = () => {
    return (
      <Box>
        {headerLinks.map((link, index) => (
          <NavLink
            key={index}
            to={link.url}
            style={{
              width: "60px",
              textAlign: "center",
              marginLeft: "40px",
              color: location.pathname.includes(link.url) ? "#000" : "#999",
            }}
          >
            {link.name}
          </NavLink>
        ))}
      </Box>
    );
  };

  return (
    <Header>
      <Link to="/product">
        <Image src={wirevision_logo} alt={"wirevision logo"} p="1" height={"40px"} />
      </Link>
      <MenuItems />
      <Spacer />
      <Button
        variant={"ghost"}
        onClick={() => {
          if (setUser) {
            setUser(null);
            navigate("/");
          }
        }}
      >
        Sign out
      </Button>
    </Header>
  );
};
