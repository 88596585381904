import { Box, VStack } from "@wiretronic/iris";
import { ReactNode } from "react";

export default function SideBar(props: { TopElement: ReactNode; ListElement: ReactNode }) {
  return (
    <VStack
      borderLeft={"solid thin var(--chakra-colors-chakra-border-color)"}
      overflowY="scroll"
      height={"100%"}
      width="350px"
    >
      <Box position={"relative"} paddingBottom={"45px"} width={"100%"}>
        {props.TopElement}
      </Box>
      {props.ListElement}
    </VStack>
  );
}
