export type settingValues = {
  name: string;
  label: string;
  defaultValue: string;
  placeholder: string;
  helpText: string;
};

export type settingType = {
  [key: string]: settingValues;
};

export const settings: settingType = {
  article_number_key: {
    name: "article_number_key",
    defaultValue: "manufacturer_article_number",
    placeholder: "Article number key",
    label: "Article number key",
    helpText: "What to look for when searching for a product",
  },
  api_key: {
    name: "api_key",
    defaultValue: "",
    placeholder: "PDB API key",
    label: "PDB API key",
    helpText: "The API key used to make requests to the database",
  },
};

class Setting {
  public readonly name: string;
  public readonly defaultValue: string;

  constructor(key: string) {
    this.name = settings[key].name;
    this.defaultValue = settings[key].defaultValue;
  }

  getStoredValue = (): string => {
    let value = localStorage.getItem(this.name);
    if (value === null || value === "") {
      value = this.defaultValue;
    }
    return value;
  };

  setValue = (value: string): void => {
    localStorage.setItem(this.name, value);
  };
}

export default Setting;
